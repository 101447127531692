
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as index4nVS2Dd4a5t9zrU2j8coCxsqtPYupm_GEv_452EzeGhJUMeta } from "/app/app/pages/index.vue?macro=true";
import { default as searchkbb43gtkc60rGLWak_E_456zSn2BTK1bKkOmKyUR_45kXcMMeta } from "/app/app/pages/search.vue?macro=true";
import { default as navigate6ZPUBbh_45xxJCwOq3_7El8SGsWrjji2ZHDRPF_45DWBxnMMeta } from "/app/app/pages/navigate.vue?macro=true";
import { default as _91_46_46_46slug_93eDHq9Sb_45fpMilQ4SKVcOZkf94gCZN8K0Lr0WXAEt9UMMeta } from "/app/app/pages/[...slug].vue?macro=true";
import { default as _91id_93OtSLnVnyUop6p92NtgIUIiWEG5PYEYyJkj_45lQ7Uc3U4Meta } from "/app/app/pages/[view]/[id].vue?macro=true";
import { default as next_46clientOqVdoshiisTJ1BSlRcnCKBwYhTafLnuWWdGeelTpYYAMeta } from "/app/app/pages/next.client.vue?macro=true";

let _createClientPage
async function createClientPage(loader) {
  _createClientPage ||= await import("/app/node_modules/.pnpm/nuxt@3.16.2_@biomejs+biome@1.9.4_@parcel+watcher@2.5.1_@types+node@22.13.4_better-sqlit_c9799edbd38d9e1ee5106d7f5bca9099/node_modules/nuxt/dist/components/runtime/client-component.js").then(r => r.createClientPage)
  return _createClientPage(loader);
}
import { default as _91name_93LB3H43qpqK4OJRSrngteURKTqJGa7Vm_45ArUG4jGboxAMeta } from "/app/app/pages/about/[name].vue?macro=true";
import { default as _91id_93e9yv4B24oGiemF0VLTH_24QBQFb0rE8LvlUmAQdeVO0Meta } from "/app/app/pages/calendar/[id].vue?macro=true";
export default [
  {
    name: "index___en",
    path: "/en",
    component: () => import("/app/app/pages/index.vue")
  },
  {
    name: "index___de",
    path: "/",
    component: () => import("/app/app/pages/index.vue")
  },
  {
    name: "search___en",
    path: "/en/search",
    component: () => import("/app/app/pages/search.vue")
  },
  {
    name: "search___de",
    path: "/search",
    component: () => import("/app/app/pages/search.vue")
  },
  {
    name: "navigate___en",
    path: "/en/navigate",
    meta: navigate6ZPUBbh_45xxJCwOq3_7El8SGsWrjji2ZHDRPF_45DWBxnMMeta || {},
    component: () => import("/app/app/pages/navigate.vue")
  },
  {
    name: "navigate___de",
    path: "/navigate",
    meta: navigate6ZPUBbh_45xxJCwOq3_7El8SGsWrjji2ZHDRPF_45DWBxnMMeta || {},
    component: () => import("/app/app/pages/navigate.vue")
  },
  {
    name: "slug___en",
    path: "/en/:slug(.*)*",
    component: () => import("/app/app/pages/[...slug].vue")
  },
  {
    name: "slug___de",
    path: "/:slug(.*)*",
    component: () => import("/app/app/pages/[...slug].vue")
  },
  {
    name: "view-id___en",
    path: "/en/:view()/:id()",
    meta: _91id_93OtSLnVnyUop6p92NtgIUIiWEG5PYEYyJkj_45lQ7Uc3U4Meta || {},
    component: () => import("/app/app/pages/[view]/[id].vue")
  },
  {
    name: "view-id___de",
    path: "/:view()/:id()",
    meta: _91id_93OtSLnVnyUop6p92NtgIUIiWEG5PYEYyJkj_45lQ7Uc3U4Meta || {},
    component: () => import("/app/app/pages/[view]/[id].vue")
  },
  {
    name: "next___en",
    path: "/en/next",
    component: () => createClientPage(() => import("/app/app/pages/next.client.vue"))
  },
  {
    name: "next___de",
    path: "/next",
    component: () => createClientPage(() => import("/app/app/pages/next.client.vue"))
  },
  {
    name: "about-name___en",
    path: "/en/about/:name()",
    component: () => import("/app/app/pages/about/[name].vue")
  },
  {
    name: "about-name___de",
    path: "/about/:name()",
    component: () => import("/app/app/pages/about/[name].vue")
  },
  {
    name: "calendar-id___en",
    path: "/en/calendar/:id()",
    component: () => import("/app/app/pages/calendar/[id].vue")
  },
  {
    name: "calendar-id___de",
    path: "/calendar/:id()",
    component: () => import("/app/app/pages/calendar/[id].vue")
  }
]