import revive_payload_client_NiOwORHfkbN8aMOrLu8IPGCaVNmruaHdpSrn7CtfTMw from "/app/node_modules/.pnpm/nuxt@3.16.2_@biomejs+biome@1.9.4_@parcel+watcher@2.5.1_@types+node@22.13.4_better-sqlit_c9799edbd38d9e1ee5106d7f5bca9099/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead__h_Kz1acgQfRbapFcJQy_D4jesVbV7o4UDvh1qbbvC0 from "/app/node_modules/.pnpm/nuxt@3.16.2_@biomejs+biome@1.9.4_@parcel+watcher@2.5.1_@types+node@22.13.4_better-sqlit_c9799edbd38d9e1ee5106d7f5bca9099/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Fj6r_3ZccIl9439Dc23rowsVO6OgTOCpm_Flqjv2lX8 from "/app/node_modules/.pnpm/nuxt@3.16.2_@biomejs+biome@1.9.4_@parcel+watcher@2.5.1_@types+node@22.13.4_better-sqlit_c9799edbd38d9e1ee5106d7f5bca9099/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_T0ErhCRBbn8r8qRkVZYyKZp81LJDtFVyXYhODYalSo4 from "/app/node_modules/.pnpm/nuxt@3.16.2_@biomejs+biome@1.9.4_@parcel+watcher@2.5.1_@types+node@22.13.4_better-sqlit_c9799edbd38d9e1ee5106d7f5bca9099/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_b5sn_bIJsxYnSfXOtzJnvE5qcjhKdCS_zXT6Yv8nneo from "/app/node_modules/.pnpm/nuxt@3.16.2_@biomejs+biome@1.9.4_@parcel+watcher@2.5.1_@types+node@22.13.4_better-sqlit_c9799edbd38d9e1ee5106d7f5bca9099/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_JHePu9V_0Tjvm__x0ckGXMIvyg_EnWGqTWZQ1AMJlSM from "/app/node_modules/.pnpm/nuxt@3.16.2_@biomejs+biome@1.9.4_@parcel+watcher@2.5.1_@types+node@22.13.4_better-sqlit_c9799edbd38d9e1ee5106d7f5bca9099/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_qUXap4ZPbldpaW6qUDPMMHqoHwGgWksvL_Wksmd9N74 from "/app/node_modules/.pnpm/nuxt@3.16.2_@biomejs+biome@1.9.4_@parcel+watcher@2.5.1_@types+node@22.13.4_better-sqlit_c9799edbd38d9e1ee5106d7f5bca9099/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_4kY4pyzJIYX99vmMAAIorFf3CnAaptHitJgf7JxiED8 from "/app/node_modules/.cache/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_l8jpktI2PDSa8Sq1DPrsW9S1Z8yuwkDYd2Gi6CN2uCw from "/app/node_modules/.pnpm/nuxt@3.16.2_@biomejs+biome@1.9.4_@parcel+watcher@2.5.1_@types+node@22.13.4_better-sqlit_c9799edbd38d9e1ee5106d7f5bca9099/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_hfhi7EvGMZ1W8_oCOXoqkQnkKm8rhjarvv8OwRMckSo from "/app/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import switch_locale_path_ssr_RBzid0muDr5Qay1SbKYLC8hGlOmnnv4Da_BcKM2sx80 from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_@vue+compiler-dom@3.5.13_eslint@9.23.0_jiti@2.4.2__magicast@0.3.5_ro_497193ab634db7ab95856217f2cd219d/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_mkoP7qS1g9SNyhRCFiqlEIP_WLuHlv_YBUf_vsmIwLQ from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_@vue+compiler-dom@3.5.13_eslint@9.23.0_jiti@2.4.2__magicast@0.3.5_ro_497193ab634db7ab95856217f2cd219d/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_eVMlPbXFHp2PTdslsTkaHjoqsGsbw51RjsXwiPZAN3s from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_@vue+compiler-dom@3.5.13_eslint@9.23.0_jiti@2.4.2__magicast@0.3.5_ro_497193ab634db7ab95856217f2cd219d/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import ssg_detect_3fBL8KTypm9KeFcBql0JRRmLXgbAMOh_TIDyZywdcD4 from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_@vue+compiler-dom@3.5.13_eslint@9.23.0_jiti@2.4.2__magicast@0.3.5_ro_497193ab634db7ab95856217f2cd219d/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  revive_payload_client_NiOwORHfkbN8aMOrLu8IPGCaVNmruaHdpSrn7CtfTMw,
  unhead__h_Kz1acgQfRbapFcJQy_D4jesVbV7o4UDvh1qbbvC0,
  router_Fj6r_3ZccIl9439Dc23rowsVO6OgTOCpm_Flqjv2lX8,
  payload_client_T0ErhCRBbn8r8qRkVZYyKZp81LJDtFVyXYhODYalSo4,
  navigation_repaint_client_b5sn_bIJsxYnSfXOtzJnvE5qcjhKdCS_zXT6Yv8nneo,
  check_outdated_build_client_JHePu9V_0Tjvm__x0ckGXMIvyg_EnWGqTWZQ1AMJlSM,
  chunk_reload_client_qUXap4ZPbldpaW6qUDPMMHqoHwGgWksvL_Wksmd9N74,
  components_plugin_4kY4pyzJIYX99vmMAAIorFf3CnAaptHitJgf7JxiED8,
  prefetch_client_l8jpktI2PDSa8Sq1DPrsW9S1Z8yuwkDYd2Gi6CN2uCw,
  plugin_client_hfhi7EvGMZ1W8_oCOXoqkQnkKm8rhjarvv8OwRMckSo,
  switch_locale_path_ssr_RBzid0muDr5Qay1SbKYLC8hGlOmnnv4Da_BcKM2sx80,
  route_locale_detect_mkoP7qS1g9SNyhRCFiqlEIP_WLuHlv_YBUf_vsmIwLQ,
  i18n_eVMlPbXFHp2PTdslsTkaHjoqsGsbw51RjsXwiPZAN3s,
  ssg_detect_3fBL8KTypm9KeFcBql0JRRmLXgbAMOh_TIDyZywdcD4
]